
import { FormController, IVForm, validationRules } from '@icepanel/app-form'
import { getAuth, getRedirectResult, SAMLAuthProvider, User } from 'firebase/auth'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import { AlertModule } from '@/modules/alert/store'
import { EditorModule } from '@/modules/editor/store'
import * as authListener from '@/modules/user/helpers/auth-listener'
import { UserModule } from '@/modules/user/store'
import * as firebase from '@/plugins/firebase'

import * as analytics from '../helpers/analytics'
import { ShareModule } from '../store'

@Component({
  name: 'ShareLogin'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Ref() readonly form!: IVForm

  loading = true

  get shareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  formController = new FormController({
    initialModel: {
      email: typeof this.$route.query.email === 'string' ? this.$route.query.email : '',
      user: undefined as User | undefined
    },
    validationRules: {
      email: [
        ...validationRules.exists,
        ...validationRules.email
      ]
    }
  })

  async created () {
    this.formController.submitHandler = async model => {
      const email = model.email.toLowerCase()
      const [, domain] = email.split('@')

      if (!model.user) {
        await this.$replaceQuery({
          email
        })
        await this.userModule.userEmailPrepare({
          email
        })

        authListener.initialize()

        if (this.shareModule.shareLinkInFrame || env.IS_SAFARI) {
          model.user = await this.userModule.firebaseLogin({
            app: firebase.app,
            popupProvider: new SAMLAuthProvider(`saml.${domain}`)
          })
        } else {
          await this.userModule.firebaseLogin({
            app: firebase.app,
            redirectProvider: new SAMLAuthProvider(`saml.${domain}`)
          })
        }
      }

      if (!model.user) {
        return
      }

      if (model.user.email && model.email && model.user.email.toLowerCase() !== model.email.toLowerCase()) {
        this.formController.model.user = undefined
        throw new Error('This account did not match the email address provided')
      } else if (this.userModule.firebaseUser) {
        await this.editorModule.loadResources()

        const shortId = this.$params.shortId
        const optionsShortId = this.$params.optionsShortId

        await this.shareModule.shareLinkPublicFind(this.$params.shortId)

        const { landscape, version } = await this.shareModule.shareLinkPublicLandscapeFind(shortId)

        if (optionsShortId) {
          await this.shareModule.shareLinkPublicOptionsFind({
            optionsShortId,
            shortId
          })
        }

        if (!this.shareModule.shareLinkOptions) {
          this.$router.push('share-link-not-found')
          return
        }

        analytics.shareLinkLogin.track(this, {
          landscapeId: [landscape.id],
          organizationId: [landscape.organizationId],
          shareLinkAuthProvider: 'saml',
          shareLinkFrameLocation: this.shareModule.shareLinkFrameLocation || undefined,
          shareLinkInFrame: this.shareModule.shareLinkInFrame,
          shareLinkProtected: true,
          shareLinkShortId: this.$params.shortId,
          versionLatest: version.tags.includes('latest'),
          versionModelId: version.modelHandleId
        })

        if (typeof this.$query.route === 'string') {
          await this.$router.push({
            name: this.$query.route,
            params: this.$route.params,
            query: {
              ...this.$route.query,
              email: undefined,
              route: undefined
            }
          })
          return
        }

        if (this.shareModule.shareLinkOptions.mode === 'diagrams') {
          await this.$router.push({
            name: 'share-diagrams',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              domain: this.shareModule.shareLinkOptions.domainId || undefined,
              ...this.$route.query,
              email: undefined
            }
          })
        } else if (this.shareModule.shareLinkOptions.mode === 'model-objects') {
          await this.$router.push({
            name: 'share-model-objects',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              domain: this.shareModule.shareLinkOptions.domainId || undefined,
              expanded: this.shareModule.shareLinkOptions.expandedIds?.length ? this.shareModule.shareLinkOptions.expandedIds : undefined,
              filter: this.shareModule.shareLinkOptions.filterIds?.length ? this.shareModule.shareLinkOptions.filterIds : undefined,
              filter_exclude: this.shareModule.shareLinkOptions.filterIdsExcluded?.length ? this.shareModule.shareLinkOptions.filterIdsExcluded : undefined,
              filter_include: this.shareModule.shareLinkOptions.filterIdsIncluded?.length ? this.shareModule.shareLinkOptions.filterIdsIncluded : undefined,
              object: this.shareModule.shareLinkOptions.objectIds?.length ? this.shareModule.shareLinkOptions.objectIds : undefined,
              object_tab: this.shareModule.shareLinkOptions.objectTab || undefined,
              search: this.shareModule.shareLinkOptions.search || undefined,
              y: this.shareModule.shareLinkOptions.y ? `${this.shareModule.shareLinkOptions.y}` : undefined,
              ...this.$route.query,
              email: undefined
            }
          })
        } else if (this.shareModule.shareLinkOptions.mode === 'diagram' || this.shareModule.shareLinkOptions.mode === 'flow') {
          await this.$router.push({
            name: 'share-diagram',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              connection: this.shareModule.shareLinkOptions.connectionIds?.length ? this.shareModule.shareLinkOptions.connectionIds : undefined,
              diagram: this.shareModule.shareLinkOptions.diagramId || undefined,
              drawer: this.shareModule.shareLinkOptions.drawer || undefined,
              flow: this.shareModule.shareLinkOptions.flowId || undefined,
              flow_parent: this.shareModule.shareLinkOptions.flowParentIds || undefined,
              flow_path: this.shareModule.shareLinkOptions.flowPathIds || undefined,
              flow_step: this.shareModule.shareLinkOptions.flowStepId || undefined,
              model: this.shareModule.shareLinkOptions.modelId || undefined,
              object: this.shareModule.shareLinkOptions.objectIds?.length ? this.shareModule.shareLinkOptions.objectIds : undefined,
              object_tab: this.shareModule.shareLinkOptions.objectTab || undefined,
              overlay_focus: this.shareModule.shareLinkOptions.overlayIdsFocused?.length ? this.shareModule.shareLinkOptions.overlayIdsFocused : undefined,
              overlay_group: this.shareModule.shareLinkOptions.overlayGroupId || undefined,
              overlay_hide: this.shareModule.shareLinkOptions.overlayIdsHidden?.length ? this.shareModule.shareLinkOptions.overlayIdsHidden : undefined,
              overlay_pin: this.shareModule.shareLinkOptions.overlayIdsPinned?.length ? this.shareModule.shareLinkOptions.overlayIdsPinned : undefined,
              overlay_tab: this.shareModule.shareLinkOptions.overlayTab || undefined,
              x1: this.shareModule.shareLinkOptions.x1 ? `${this.shareModule.shareLinkOptions.x1}` : undefined,
              x2: this.shareModule.shareLinkOptions.x2 ? `${this.shareModule.shareLinkOptions.x2}` : undefined,
              y1: this.shareModule.shareLinkOptions.y1 ? `${this.shareModule.shareLinkOptions.y1}` : undefined,
              y2: this.shareModule.shareLinkOptions.y2 ? `${this.shareModule.shareLinkOptions.y2}` : undefined,
              ...this.$route.query,
              email: undefined
            }
          })
        } else {
          await this.$router.push({
            name: 'share-landscape',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              ...this.$route.query,
              email: undefined
            }
          })
        }
      }
    }

    try {
      const userCredential = await getRedirectResult(getAuth(firebase.app))
      if (userCredential) {
        this.formController.model.user = userCredential.user
        this.formController.submit()
      }
    } catch (err: any) {
      this.formController.errorMessage = err.message
    }

    if (this.$route.query.login) {
      this.formController.submit()
    }
  }

  mounted () {
    analytics.shareLinkLoginScreen.track(this, {
      shareLinkAuthProvider: 'saml',
      shareLinkFrameLocation: this.shareModule.shareLinkFrameLocation || undefined,
      shareLinkInFrame: this.shareModule.shareLinkInFrame,
      shareLinkProtected: true,
      shareLinkShortId: this.$params.shortId
    })
  }
}
